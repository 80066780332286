*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* -- default small mobile -- */

div.grid-container {
  padding: 20px;
}

div.grid-child {
  font-size: 40px;
  text-align: left;
  background-color: #282c34;
  padding: 40px;
}

div.grid-child {
  font-size: 20px;
}

img.icon {
  height: 30px;
}

form {
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-gap: 8px;
}

label {
  grid-column: 1/1;
}

input {
  grid-column-start: 2;
  grid-column-end: 3;
  width: 80%;
  font-size: 18px;
  border-radius: 8px;
}

select {
  appearance: none;
  background-color: #fff;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 80%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  grid-column: 2/3;
}

span.result {
  width: 80%;
  grid-column: 2/3;
  text-overflow: clip;
  color: #ffffff;
  font-weight: bold;
  border-bottom: 2px solid #ffff00;
}

span.number {
  width: 80%;
  grid-column: 2/3;
  text-overflow: clip;
  color: #ffffff;
  font-weight: bold;
}

button {
  margin: 60px;
  width: 300px;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  background-color: #ff4500;
  border-radius: 8px;
  border: 2px solid #000;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #cf3500;
}

@media screen and (min-width: 900px) {
  div.grid-container {
    padding: 20px;
  }

  div.grid-child {
    font-size: 40px;
    text-align: left;
    background-color: #282c34;
    padding: 40px;
  }

  div.grid-child {
    font-size: 40px;
  }

  img.icon {
    height: 60px;
  }

  form {
    display: grid;
    grid-template-columns: 380px 1fr;
    grid-gap: 16px;
  }

  label {
    grid-column: 1/1;
  }

  input {
    grid-column-start: 2;
    grid-column-end: 3;
    width: 300px;
    font-size: 36px;
    border-radius: 8px;
  }

  select {
    appearance: none;
    background-color: #fff;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 300px;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    grid-column: 2/3;
  }

  span.result {
    width: 300px;
    grid-column: 2/3;
    text-overflow: clip;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 2px solid #ffff00;
  }

  span.number {
    width: 300px;
    grid-column: 2/3;
    text-overflow: clip;
    color: #ffffff;
    font-weight: bold;
  }

  button {
    margin: 60px;
    width: 300px;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    background-color: #ff4500;
    border-radius: 8px;
    border: 2px solid #000;
    color: #fff;
    cursor: pointer;
  }

  button:hover {
    background-color: #cf3500;
  }
}

@media screen and (min-width: 1600px) {

  div.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
  }

  div.grid-child {
    font-size: 40px;
    text-align: left;
    background-color: #282c34;
    padding: 40px;
  }

  div.grid-left {
    border-right: 1px solid #fff;
  }

  div.grid-child {
    font-size: 40px;
  }

  img.icon {
    height: 60px;
  }

  h1 {
    text-shadow: 2px 2px 8px #000000;
    margin-top: 0;
    white-space: nowrap;
  }

  form {
    display: grid;
    grid-template-columns: 380px 1fr;
    grid-gap: 16px;
  }

  label {
    grid-column: 1/1;
  }

  input {
    grid-column-start: 2;
    grid-column-end: 3;
    width: 300px;
    font-size: 36px;
    border-radius: 8px;
  }

  select {
    appearance: none;
    background-color: #fff;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 300px;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    grid-column: 2/3;
  }

  span.result {
    width: 300px;
    grid-column: 2/3;
    text-overflow: clip;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 2px solid #ffff00;
  }

  span.number {
    width: 300px;
    grid-column: 2/3;
    text-overflow: clip;
    color: #ffffff;
    font-weight: bold;
  }

  button {
    margin: 60px;
    width: 300px;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    background-color: #ff4500;
    border-radius: 8px;
    border: 2px solid #000;
    color: #fff;
    cursor: pointer;
  }

  button:hover {
    background-color: #cf3500;
  }

}